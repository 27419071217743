<template>
  <div class="user content pos-r z1">
    <BaseLoad v-if="isLoad" class="grid jc-c z99999 bg" />
    <div class="box pt-20 pb-20 mt-20 mb-20">
      <template v-if="isFinished && user">
        <!-- User -->
        <div class="user__profile flex fd-c ai-c jc-c ggap-10 ai-c mb-20">
          <div class="user__profile-ava ava-150 cover">
            <img :src="user.avatar || require('@/assets/img/ava.svg')" />
          </div>
          <div class="user__profile-data flex fd-c ai-c jc-c">
            <h1 class="m-0 mb-5">{{ user.fullname }}</h1>
            <div class="fs-14">{{ user.specialization || 'Нет должности' }}</div>
          </div>
        </div>

        <!-- Stat -->
        <div v-if="false" class="user__stat grid gtc-4 ggap-30 m-auto">
          <span class="flex fd-c ai-c jc-c box p-10">
            <h1 class="title">230</h1>
            <small class="t-grey-dark">Всего заявок</small>
          </span>
          <span class="flex fd-c ai-c jc-c box p-10">
            <h1 class="title">1</h1>
            <small class="t-grey-dark">Активных заявок</small>
          </span>
          <span class="flex fd-c ai-c jc-c box p-10">
            <h1 class="title">12</h1>
            <small class="t-grey-dark">Успешных заявок</small>
          </span>
          <span class="flex fd-c ai-c jc-c box p-10">
            <h1 class="title">217</h1>
            <small class="t-grey-dark">Отклоненных заявок</small>
          </span>
        </div>

        <!-- Nav -->
        <nav v-if="currentUser.id === user.id" class="user__nav flex ai-c jc-c ggap-20 mt-30">
          <RouterLink
            :to="'/user/' + user.id"
            class="user__nav-item flex ai-c ggap-10 pb-10"
            exact-active-class="active"
          >
            <BaseIcon class="ic-16 primary" icon="user" />
            Мой профиль
          </RouterLink>
          <RouterLink
            :to="'/user/' + user.id + '/settings'"
            class="user__nav-item flex ai-c ggap-10 pb-10"
            exact-active-class="active"
          >
            <BaseIcon class="ic-16 primary" icon="settings" />
            Настройки
          </RouterLink>
          <a
            v-if="currentUser.id === user.id"
            href="#"
            class="user__nav-item logout flex ai-c ggap-10 pb-10"
            @click="logoutAction"
          >
            <BaseIcon class="ic-16 red" icon="power" />
            Выйти
          </a>
        </nav>
        <hr v-else class="mt-20" />

        <!-- Content -->
        <div class="user__content m-auto pt-20">
          <RouterView />
        </div>
      </template>
      <template v-else>
        <div class="flex ai-c jc-c t-red">Что-то пошло не так, либо такого пользователя не существует!</div>
      </template>
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
import { computed, ref, nextTick, watch } from 'vue'
import { BaseLoad, BaseIcon } from '@/components'
import { useRoute } from 'vue-router'

// Data
const store = useStore()
const route = useRoute()
const isLoad = ref(true)
const isFinished = ref(false)

// Computed
const currentUser = computed(() => {
  return store.getters.profile
})
const user = computed(() => {
  return store.getters?.user
})

// Watch
watch(
  () => route.params.id,
  async (newId) => {
    await getUser(newId)
  }
)

// Created
getUser()

// Methods
async function logoutAction() {
  localStorage.removeItem(`${store.getters.prefix}_token`)
  localStorage.removeItem(`${store.getters.prefix}_profile`)
  await nextTick()
  store.commit('CLEAR_STATE')
  await nextTick()
  window.location.replace('https://intra.chocofamily.kz/app?logout')
}
async function getUser() {
  isLoad.value = true
  await store.dispatch('GET_USER', route.params.id)
  isFinished.value = true
  isLoad.value = false
}
</script>

<style lang="scss" scoped>
.user {
  overflow: auto;
  min-height: calc(100vh - 300px);

  &__content,
  &__stat {
    max-width: 700px;
  }

  &__nav {
    border-bottom: var(--b1);

    &-item {
      text-decoration: none;
      color: var(--text);

      &.active {
        color: var(--primary);
        box-shadow: 0 2px 0 0 var(--primary);
      }
    }
  }
}
</style>
